/**
* Template Name: Medilab - v2.1.1
* Template URL: https://bootstrapmade.com/medilab-free-medical-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
$ = jQuery;
!(function($) {
  "use strict";

  $(window).scroll(function() {
    var windowTop = $(window).scrollTop();
    //console.log(windowTop);
  
    if($('.top').length){
      if (windowTop > 45) {
        $('.top').addClass("sticky");
      } else{
        $('.top').removeClass("sticky");
      }
    }
  });
  
})(jQuery);

$(document).ready(function() {
  /*AOS.init({
    duration: 1500,
  })*/

    $('.menurs').hover(function() {
      $(this).toggleClass('act');
      
  }
  );

  $('li.menu-item-has-children .sub-menu').hide();
  $("li.menu-item-has-children").click(function(e){
    //e.preventDefault();
    if (! $(this).hasClass('act')) {
      $('.menu-mobile li.menu-item-has-children').removeClass('act');
      $('.menu-mobile li.menu-item-has-children .sub-menu').slideUp(300);
      $(this).addClass("act").find('.sub-menu').slideToggle(300);
    }
  });
  $('a[href="#search"]').on('click', function(event) {
    event.preventDefault();
    $('#fullscreensearch').addClass('open');
    $('#fullscreensearch > form > input[type="text"]').focus();
  });
  $('#search-close').on('click', function(event) {
    event.preventDefault();
    $('#fullscreensearch').removeClass('open');
  });
  $("#rade_img_map_1335199662212").hover(function(){
    $("li#rs1").addClass("active");  //Add the active class to the area is hovered
    }, function () {
    $("li#rs1").removeClass("active");
});


});
// Js pour sidebar menu
const menuMobile =  document.querySelector(".menu-mobile");
function openNav(){
    menuMobile.style.transform = "translate(0,0)";
}
function closeNav(){
    menuMobile.style.transform = "translate(100%,0)";
}

